<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Новый код</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="form.data.discountId"
            label="Программа скидок"
            item-text="promotion"
            item-value="id"
            :items="discounts"
            clearable
            return-object
            :rules="form.rule.discountId"
          />
          <v-text-field
            v-model="form.data.code"
            label="Промокод"
            counter="150"
            hint="Значение промокода"
            :rules="form.rule.code"
          />
          <v-text-field
            v-model="form.data.issued"
            label="Тираж"
            counter="12"
            :rules="form.rule.issued"
            hint="Количество кодов, которые могут быть применены"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'discount-code.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import DiscountCodeCreateForm from '@/model/discount/DiscountCodeCreateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountCodeCreatePage',
  data() {
    return {
      form: {
        valid: true,
        data: {
          discountId: null,
          code: '',
          issued: null
        },
        rule: {
          discountId: [ruleRequired()],
          code: [ruleRequired()],
          issued: [ruleRequired(), ruleNumeric()]
        }
      }
    }
  },

  computed: {
    ...mapState('discount_code_create', ['busy', 'discounts'])
  },
  created() {
    this.fetchData()
      .then(() => {
      })
  },
  methods: {
    ...mapActions('discount_code_create', ['fetchData', 'createDiscountCode']),
    submitForm() {
      const discountId = this.form.data.discountId?.id ?? null

      const form = {
        ...DiscountCodeCreateForm.create(this.form.data),
        issued: parseInt(this.form.data.issued),
        discountId
      }

      this.createDiscountCode({ form })
        .then(() => this.$router.push({ name: 'discount-code.list' }))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    revalidateForm() {
      this.$refs.form.validate()
    }
  }
}
</script>
