export default class DiscountCodeCreateForm {
  constructor(data) {
    this.discountId = data?.discountId
    this.code = data?.code
    this.issued = data?.issued
  }

  static create(data) {
    return new DiscountCodeCreateForm(data)
  }
}
